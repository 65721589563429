import React, { Component } from "react";
import { Form, Icon, Input, Button, message, Modal, Select } from "antd";
import { Link } from "react-router-dom";
import { setItem, KEYS } from "../../utils/localStorage";
import axios from "axios";
import "./LoginForm.css";
import { baseURL } from "../../utils/application";

const FormItem = Form.Item;
const { Option } = Select;
class LoginForm1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: "",
      password: "",
      tabKey: "",//1为学生，2为教师
      schoolPre: "",//最后选定的学校
      uploading: false,
      allSchools: [], //所有注册用户涉及的学校
      filteredSchools: [], // 搜索过滤后的学校名称列表
    };
  }

  componentDidMount = () => {
    this.getAllSchInfo();
  };
  componentWillUnmount = () => {
    this.setState = (state, callback) => {
      return;
    };
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ uploading: true });
    const { account, password, schoolPre } = this.state;
    const { history, tabKey } = this.props;
    if (account && password) {
      if (tabKey === "1") {
        const res = await axios({
          method: "get",
          url: baseURL + "/login",
          params: {
            username: schoolPre + account,
            password: password,
            type: "student",
          },
        });
        if (res && res.status === 200) {
          console.log(res);
          if (res.data.userId) {
            message.info("登录成功！！！", 2);
            setItem(KEYS.KEY_CUR_USERINFO, JSON.stringify(res.data));
            setTimeout(() => {
              history.push("/homePage");
              this.setState({
                uploading: false,
              });
            }, 1500);
          } else {
            if (res.data) {
              message.error(res.data.userName, 2);
            } else {
              message.error("账号或密码错误！！！", 2);
            }
            setTimeout(() => {
              this.setState({
                uploading: false,
              });
            }, 1500);
          }
        } else {
          message.error("请求登录失败！！！", 2);
          setTimeout(() => {
            this.setState({
              uploading: false,
            });
          }, 1500);
        }
      }
      if (tabKey === "2") {
        const res = await axios({
          method: "get",
          url: baseURL + "/login",
          params: {
            username: account,
            password: password,
            type: "teacher",
          },
        });
        if (res && res.status === 200) {
          if (res.data.userId) {
            message.info("登录成功！！！", 1);
            setItem(KEYS.KEY_CUR_USERINFO, JSON.stringify(res.data));
            setTimeout(() => {
              history.push("/homePage");
              this.setState({
                uploading: false,
              });
            }, 1500);
          } else {
            message.error("账号或密码错误！！！", 2);
            setTimeout(() => {
              this.setState({
                uploading: false,
              });
            }, 1500);
          }
        } else {
          message.error("请求登录失败！！！", 2);
          setTimeout(() => {
            this.setState({
              uploading: false,
            });
          }, 1500);
        }
      }
    } else {
      message.error("账号或者密码未输入！！！", 2);
      this.setState({
        uploading: false,
      });
    }
  };

  handleTelInput = (e) => {
    this.setState({
      account: e.target.value,
    });
  };

  setSchoolPrefix = (value) => {
    // console.log(value)
    this.setState({
      schoolPre: value,
    });
  };

  handlePassWordInput = (e) => {
    this.setState({
      password: e.target.value,
    });
  };
  retrievePassword() {
    Modal.success({
      title: "找回密码",
      content: (
        <p>
          请联系管理员： 254373014@qq.com <br />
          格式： 姓名+学号
        </p>
      ),
    });
  }

  // 处理学校名称的实时搜索
  handleSchoolSearch = (value) => {
    const inputValue = value.trim().toLowerCase();
    const filteredSchools = this.state.allSchools.filter((schoolName) =>
      schoolName.toLowerCase().includes(inputValue) // 根据拼音或汉字匹配
    );
    this.setState({ filteredSchools: filteredSchools });
  };

  getAllSchInfo = async () => {
    const res = await axios.get(baseURL + "/course/getAllSchools");
    const resSchools = res.data;
    console.log(resSchools);
    if (resSchools && resSchools.code === 200) {
      if (resSchools.data) {
        console.log(resSchools.data);
        this.setState({
          allSchools: resSchools.data,
          filteredSchools: resSchools.data,
        });
      } else {
        // message.info('此课程没有学生',2)
        return null;
      }
    } else {
      message.error("获取学校列表失败", 2);
      return null;
    }
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    // const selectBefore = getFieldDecorator("schoolPrefix", {
    //   initialValue: "",
    // })(
    //   <Select
    //     defaultValue="无"
    //     onChange={this.setSchoolPrefix}
    //     style={{ width: 115 }}
    //   >
    //     {this.state.allSchools.map((schoolName, index) => (
    //       <Option value={schoolName}>{schoolName}</Option>
    //     ))}
    //     <Option value="">无</Option>
    //   </Select>
    // );
    const selectBefore = (
      <Select
        showSearch
        style={{ width: 115 }}
        placeholder="选择学校"
        optionFilterProp="children"
        onSearch={this.handleSchoolSearch} // 处理搜索的输入
        onChange={this.setSchoolPrefix}
        filterOption={false} // 关闭内置的过滤功能，使用自定义的过滤逻辑
      >
        {this.state.filteredSchools.map((schoolName, index) => (
          <Option key={index} value={schoolName}>
            {schoolName}
          </Option>
        ))}
      </Select>
    );

    const { uploading } = this.state;
    const { tabKey } = this.props;
    return (
      <>
        <Form onSubmit={this.handleSubmit} className="login-form">
          {tabKey === "1" ? (
            <FormItem>
              {getFieldDecorator("account", {
                rules: [
                  {
                    required: true,
                    message: "请输入用户名!",
                  },
                ],
              })(
                <Input
                  prefix={<Icon type="user" style={{ color: "black" }} />}
                  placeholder="请输入用户名"
                  onChange={this.handleTelInput}
                  style={{ fontSize: "16px" }}
                  addonBefore={selectBefore}
                />
              )}
            </FormItem>
          ) : (
            <FormItem>
              {getFieldDecorator("account", {
                rules: [
                  {
                    required: true,
                    message: "请输入用户名!",
                  },
                ],
              })(
                <Input
                  prefix={<Icon type="user" style={{ color: "black" }} />}
                  placeholder="请输入用户名"
                  onChange={this.handleTelInput}
                  style={{ fontSize: "16px" }}
                />
              )}
            </FormItem>
          )}

          <FormItem>
            {getFieldDecorator("password", {
              rules: [
                {
                  required: true,
                  message: "请输入密码",
                },
              ],
            })(
              <Input
                prefix={<Icon type="lock" style={{ color: "black" }} />}
                type="password"
                placeholder="请输入密码"
                onChange={this.handlePassWordInput}
                style={{ fontSize: "16px" }}
              />
            )}
          </FormItem>
          <FormItem>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={uploading}
            >
              {uploading ? "登录中..." : "登 录"}
            </Button>
            {tabKey === "1" ? (
              <span>
                <Button className="login-form-button">
                  <Link to={"/register"}>注册</Link>
                </Button>
                {/* <Link onClick={this.retrievePassword.bind(this)}>
                    找回密码
                  </Link> */}
              </span>
            ) : (
              // <span>
              //   <Button className="login-form-button">
              //     <Link to={"/tearegister"}>注册</Link>
              //   </Button>
              // </span>
              <span>
                <Button className="login-form-button">
                  <Link to={"/register"}>注册</Link>
                </Button>
                {/* <Link onClick={this.retrievePassword.bind(this)}>
                    找回密码
                  </Link> */}
              </span>
            )}

            {/* 找回账号按钮 */}
            <div style={{ marginTop: "10px", textAlign: "center" }}>
              <Link to={"/forgot-password"} style={{ fontSize: "12px", color: "#1890ff" }}>
                找回账号
              </Link>
            </div>
            
          </FormItem>
        </Form>
      </>
    );
  }
}

const LoginForm = Form.create({ name: "normal-login" })(LoginForm1);
export default LoginForm;
